import { FC } from "react";

type UserImageProps = {
  image: string;
};

export const UserImage: FC<UserImageProps> = ({ image }) => {
  return (
    <div className="max-w-md px-4 mx-auto sm:max-w-3xl sm:p-0 sm:h-full">
      <div className="overflow-hidden shadow-xl aspect-w-10 aspect-h-8 rounded-2xl sm:aspect-none sm:h-full">
        <img className="object-cover sm:h-full sm:w-full" src={image} alt="" />
      </div>
    </div>
  );
};
