import { FC } from "react";

export const Loading: FC = () => (
  <>
    <span className="flex w-12 h-12 mx-4">
      <span className="absolute inline-flex w-full h-full rounded-full opacity-75 animation-delay-none bg-gradient-to-br from-green-300 to-green-500 animate-ping"></span>
      <span className="relative inline-flex w-12 h-12 rounded-full bg-gradient-to-br from-green-500 to-green-700"></span>
    </span>
    <span className="flex w-12 h-12 mx-4">
      <span className="absolute inline-flex w-full h-full rounded-full opacity-75 animation-delay-100 bg-gradient-to-br from-green-300 to-green-500 animate-ping"></span>
      <span className="relative inline-flex w-12 h-12 rounded-full bg-gradient-to-br from-green-500 to-green-700"></span>
    </span>
    <span className="flex w-12 h-12 mx-4">
      <span className="absolute inline-flex w-full h-full rounded-full opacity-75 animation-delay-200 bg-gradient-to-br from-green-300 to-green-500 animate-ping"></span>
      <span className="relative inline-flex w-12 h-12 rounded-full bg-gradient-to-br from-green-500 to-green-700"></span>
    </span>
  </>
);
