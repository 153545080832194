import { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { Roster } from "../../component/Roster/Roster";

const CV = lazy(() => import("../../feature/CV"));

export const About = () => {
  return (
    <Routes>
      <Route index element={<Roster />} />
      <Route path=":user" element={<CV />} />
    </Routes>
  );
};
