import { FC } from "react";
import img404 from "../img/404.jpg";

export const Error404: FC = () => (
  <main className="min-h-full bg-top bg-cover sm:bg-top bg-gradient-to-br from-green-500 to-green-700 mix-blend-multiply">
    <div className="absolute inset-0 opacity-50 filter saturate-0 mix-blend-multiply">
      <img src={img404} alt="" className="object-cover w-full h-full" />
    </div>
    <div className="px-4 py-16 mx-auto text-center max-w-7xl sm:px-6 sm:py-24 lg:px-8 lg:py-48">
      <p className="font-semibold tracking-wide uppercase text-white/50 text">
        404 error
      </p>
      <h1 className="mt-4 text-4xl font-extrabold tracking-tight text-white sm:text-5xl">
        Uh oh!
        <br />
        Ich glaube du bist hier falsch.
      </h1>
      <p className="mt-2 text-xl font-medium text-white/50">
        Es sieht so aus, als ob du hier gestrandet bist.
      </p>
    </div>
  </main>
);

export default Error404;
