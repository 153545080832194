import { FC, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Loading } from "./component/Loading";
import Error404 from "./error/Error404";
import { About } from "./routes/about/About";

export const App: FC = () => (
  <Router>
    <Suspense
      fallback={
        <div className="flex items-center justify-center w-full h-full">
          <Loading />
        </div>
      }
    >
      <Routes>
        <Route index path="/*" element={<Error404 />} />
        <Route path="/about/*" element={<About />} />
      </Routes>
    </Suspense>
  </Router>
);

export default App;
