import andreas from "../../img/profile-andreas.jpg";
import michael from "../../img/profile-michael.jpg";
import sergej from "../../img/profile-sergej.jpg";
import { RosterItem } from "./RosterItem";

export const Roster = () => {
  return (
    <>
      <div className="max-w-5xl px-4 py-16 mx-auto sm:py-24 sm:px-6 lg:px-8 bb-page">
        <div className="text-center">
          <h2 className="text-lg font-semibold tracking-wide text-green-700 uppercase sm:tracking-wider">
            Roster
          </h2>
          <p className="mt-1 text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
            Das Team
          </p>
        </div>
      </div>

      <div className="relative sm:bg-opacity-25 sm:bg-gradient-to-br sm:from-green-500 sm:to-green-700">
        <div className="mx-auto max-w-7xl sm:px-8 sm:grid sm:grid-cols-3 sm:gap-8">
          <RosterItem
            id="sergej"
            name="Sergej Kutschin"
            description="Full Stack Developer"
            image={sergej}
          />
          <RosterItem
            id="michael"
            className="mt-8"
            name="Michael Kutschin"
            description="Backend Developer"
            image={michael}
          />
          <RosterItem
            id="andreas"
            className="mt-8"
            name="Andreas Mann"
            description="Full Stack Developer"
            image={andreas}
          />
        </div>
      </div>
    </>
  );
};
