import { FC } from "react";
import { Link } from "react-router-dom";
import { UserImage } from "../UserImage/UserImage";

type RosterItemProps = {
  id: string;
  name: string;
  description: string;
  image: string;
} & { className?: string };

export const RosterItem: FC<RosterItemProps> = ({
  id,
  name,
  description,
  image,
  className = "",
}) => {
  return (
    <div className={`relative sm:-my-8 ${className}`}>
      <Link to={id}>
        <UserImage image={image} />
        <h2 className="mt-2 text-4xl font-extrabold tracking-tight text-center">
          {name}
        </h2>
      </Link>
      <h2 className="mt-1 text-lg font-semibold tracking-wide text-center text-green-700 uppercase sm:tracking-wider">
        {description}
      </h2>
    </div>
  );
};
